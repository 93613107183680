import React from "react";
import { paramCase } from "change-case";
import PropTypes from "prop-types";
import Section from "../Section";
import Heading from "../Heading";
import Box from "../../base/Box/index";
import Image from "../Image";

const OurBenefits = ({ data }) => {
  const { heading, benefits } = data;
  const titleSx = {
    color: "primary",
    fontFamily: "secondary",
    fontSize: 2,
    fontWeight: "bold",
    lineHeight: 3,
    textTransform: "uppercase",
  };
  return (
    <Section sx={{ bg: "grays.0" }}>
      <Heading sx={{ ...titleSx, letterSpacing: 4, textAlign: "center" }}>
        {heading}
      </Heading>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          mt: 5,
          width: "100%",
        }}
      >
        {Array.isArray(benefits) &&
          benefits?.length > 0 &&
          benefits.map(({ benefitName, image, description }) => (
            <Box
              key={paramCase(benefitName)}
              sx={{
                alignItems: ["center", "flex-start"],
                display: "flex",
                flexDirection: "column",
                mb: 3,
                p: 2,
                textAlign: ["center", "left"],
                width: ["100%", "50%", "25%"],
              }}
            >
              {image && (
                <Image
                  img={image}
                  sx={{
                    height: "10rem",
                    objectFit: "contain",
                  }}
                />
              )}
              {benefitName && (
                <Box
                  sx={{
                    ...titleSx,
                    color: "black",
                    fontSize: [1, null, null, 2],
                    my: 3,
                    textTransform: "none",
                  }}
                >
                  {benefitName}
                </Box>
              )}
              {description && <Box sx={{ fontSize: 0 }}>{description}</Box>}
            </Box>
          ))}
      </Box>
    </Section>
  );
};
OurBenefits.propTypes = {
  data: PropTypes.shape({
    benefits: PropTypes.arrayOf({}),
    heading: PropTypes.string,
  }).isRequired,
};
export default OurBenefits;
