import React from "react";
import PropTypes from "prop-types";
import Box from "../../base/Box";
import Image from "../Image";

const PhotoCollage = ({ collageGrid, images, wrapperSx }) => {
  const tilesGap = 2;
  let startIndex = 0;
  const setTiles = (n) => {
    const gridArray = images.slice(startIndex, startIndex + n);
    startIndex += n;
    return gridArray.length > 0 ? (
      <Box sx={{ flex: "1" }}>
        {gridArray.map((image) => (
          <Box sx={{ height: `calc(100% / ${gridArray.length})`, p: tilesGap }}>
            <Image
              img={image}
              sx={{ height: "100%", objectFit: "cover", width: "100%" }}
            />
          </Box>
        ))}
      </Box>
    ) : null;
  };
  return (
    <Box
      sx={{
        display: "flex",
        flex: "auto",
        m: -tilesGap,
        maxHeight: "60rem",
        ...wrapperSx,
      }}
    >
      {(images.length === 2 ? [1, 1] : collageGrid).map((n) => setTiles(n))}
    </Box>
  );
};

PhotoCollage.propTypes = {
  collageGrid: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  wrapperSx: PropTypes.shape({}),
};

PhotoCollage.defaultProps = {
  collageGrid: [1, 2, 1],
  wrapperSx: {},
};

export default PhotoCollage;
