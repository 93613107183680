import React from "react";
import { Router } from "@reach/router";
import CareerPage from "../components/CareerPage";
import NotFoundPage from "./404";

const aboutPageRouter = (props) => (
  <Router>
    <CareerPage {...props} path="/careers" />
    <NotFoundPage default />
  </Router>
);

export default aboutPageRouter;
