import React from "react";
import PropTypes from "prop-types";
import Box from "../../base/Box";
import PhotoCollage from "../../components/PhotoCollage";
import MarkdownToHtml from "../../components/MarkdownToHtml";
import ActionButtons from "../../components/ActionButtons";
import Section from "../../components/Section";

const OurCultureSection = ({ data, sectionSx, containerSx }) => {
  const { images, textContent, actionButtons } = data;
  return (
    <Section
      containerSx={{
        alignItems: "center",
        display: "flex",
        flexDirection: ["column", null, null, "row"],
        ...containerSx,
      }}
      sx={{ bg: "primary", ...sectionSx }}
    >
      {images?.length > 0 && (
        <PhotoCollage
          collageGrid={[1, 2, 1]}
          images={images.slice(0, 4).map((i) => i.image)}
          wrapperSx={{
            pb: [3, null, null, 0],
            width: ["100%", null, null, "50%"],
          }}
        />
      )}
      <Box
        sx={{
          pl: [3, null, null, 4],
          pr: [3, null, null, 0],
          width: ["100%", null, null, "50%"],
        }}
      >
        <MarkdownToHtml
          heading={textContent?.heading}
          source={textContent?.content}
          sx={{
            color: "white",
            h2: {
              color: "primaryLight",
            },
            h3: {
              color: "white",
            },
          }}
        />
        {actionButtons?.length > 0 &&
          actionButtons.some((i) => !i.hideButton && i.buttonLabel) && (
            <ActionButtons buttons={actionButtons} />
          )}
      </Box>
    </Section>
  );
};

OurCultureSection.propTypes = {
  containerSx: PropTypes.shape({}),
  data: PropTypes.shape({
    actionButtons: PropTypes.arrayOf(PropTypes.shape({})),
    images: PropTypes.arrayOf(PropTypes.string),
    textContent: PropTypes.shape({
      content: PropTypes.string,
      heading: PropTypes.string,
    }),
  }).isRequired,
  sectionSx: PropTypes.shape({}),
};

OurCultureSection.defaultProps = {
  containerSx: {},
  sectionSx: {},
};

export default OurCultureSection;
